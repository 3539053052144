import styled from 'styled-components';

const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const white = '#FFFFFF';
const lightGray = '#f5f5f5';
const darkGray = '#333';

export const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 80px 20px;
  background-color: ${lightGray};
  color: ${darkGray};
`;

export const BlogSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlogMainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlogMenu = styled.div`
  display: flex;
  flex-direction: column;
`;


export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 40px;
  color: ${darkGreen};
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

export const PostList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  li {
    margin: 20px 0;
  }

  button {
    background-color: ${white};
    color: ${darkGreen};
    border: 2px solid ${darkGreen};
    padding: 15px 25px;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &:hover {
      background-color: ${darkGreen};
      color: ${white};
    }

    &:focus {
      outline: none;
    }
  }
`;

export const PostContent = styled.div`
  margin-top: 40px;
  max-width: 800px;
  background-color: ${white};
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  h1 {
    color: ${darkGreen};
    margin-bottom: 20px;
    font-size: 2.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: ${darkGray};
  }

  ul {
    margin-top: 20px;
    list-style-type: square;
    padding-left: 20px;
    color: ${darkGreen};
  }
`;

export const BlogImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const BlogUl = styled.ul `
`;
export const BlogLi = styled.li `
`;