import React from "react";
import * as S from "../resources/styles/ConstructionStyles";

const ConstructionPage = () => {
  return (
    <S.ConstructionContainer>
      <S.Banner>
        <S.BannerText>Estamos Atualizando o Site!</S.BannerText>
        <S.BannerSubtitle>Em breve voltaremos com novidades.</S.BannerSubtitle>
      </S.Banner>
    </S.ConstructionContainer>
  );
};

export default ConstructionPage;
