import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  HeaderContainer,
  Logo,
  NavMenu,
  NavLink,
  HamburgerMenu,
  MobileMenu,
  MobileNavLink,
} from '../resources/styles/HeaderStyles';
import logoImage from '../resources/img/logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HeaderContainer>
        <Link to="/">
        <Logo src={logoImage} alt="Logo" />
        </Link>

        <NavMenu>
          <NavLink as={Link} to="/adestramento">Adestramento</NavLink>
          <NavLink as={Link} to="/blog">Blog</NavLink>
          <NavLink as={Link} to="/cursos">Cursos</NavLink>
          <NavLink as={Link} to="/eventos">Eventos</NavLink>
          <NavLink as={Link} to="/sobre">Sobre</NavLink>
          <NavLink as={Link} to="/contato">Contato</NavLink>
          <NavLink as={Link} to="/auf">Auf!</NavLink>
        </NavMenu>

        <HamburgerMenu onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </HamburgerMenu>
      </HeaderContainer>

      <MobileMenu open={isOpen}>
        <MobileNavLink as={Link} to="/adestramento" onClick={toggleMenu}>Adestramento</MobileNavLink>
        <MobileNavLink as={Link} to="/blog" onClick={toggleMenu}>Blog</MobileNavLink>
        <MobileNavLink as={Link} to="/cursos" onClick={toggleMenu}>Cursos</MobileNavLink>
        <MobileNavLink as={Link} to="/eventos" onClick={toggleMenu}>Eventos</MobileNavLink>
        <MobileNavLink as={Link} to="/sobre" onClick={toggleMenu}>Sobre</MobileNavLink>
        <MobileNavLink as={Link} to="/contato" onClick={toggleMenu}>Contato</MobileNavLink>
        <MobileNavLink as={Link} to="/auf" onClick={toggleMenu}>Auf!</MobileNavLink>
      </MobileMenu>
    </>
  );
};

export default Header;
