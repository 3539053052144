import React from 'react';
import styled from 'styled-components';
import AufPerfil from '../resources/img/AufPerfil.jpg'
// Estilos
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 800px;
  margin: 25vh auto;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 4px solid #4caf50;
`;

const Title = styled.h1`
  font-size: 2.4rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: bold;
`;

const HighlightedText = styled.span`
  color: #4caf50;
`;

const Description = styled.p`
  font-size: 1.15rem;
  color: #7f8c8d;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 600px;
`;

const Button = styled.a`
  padding: 15px 30px;
  background-color: #4caf50;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
`;

const Auf = () => {
  return (
    <Container>
      <ProfileImage src={AufPerfil} alt="Auf Profile" />
      <Title>
        Oi! Eu sou o <HighlightedText>Auf</HighlightedText>, o assistente digit<span style={{ color: '#4caf50' }}>Au</span> do tio Fer
      </Title>
      <Description>
        Estou sendo treinado para <HighlightedText>ajudar famílias</HighlightedText> a conviver melhor com seus cães, e quero ajudar a sua também!
        <br/>
        Eu já sei ler textos e consigo entender áudios, então pode me contar tudo em detalhes
        pelo <HighlightedText>WhatsApp</HighlightedText> ou <HighlightedText>Instagram</HighlightedText>.
        <br/>
        <br/>
        Ainda estou aprendendo, então, se não souber alguma coisa, eu peço ajuda ao tio Fer para te dar a melhor resposta. 😊
      </Description>
      <Button href="https://wa.me/41985362417" target='_blank'>Fale comigo agora!</Button>
    </Container>
  );
};

export default Auf;
