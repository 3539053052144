import React from "react";
import * as S from "../resources/styles/HomeStyles";
import { Link } from "react-router-dom";


const Home = () => {
  return (
    <S.HomeContainer>
      {/* Banner */}
      <S.Banner>
        <S.BannerText>
          Transformando a comunicação <br/> entre cães e humanos
        </S.BannerText>
 
      </S.Banner>

      {/* Resumo */}
      <S.Section>
        <S.SectionTitle>Bem-vindo ao Cachorrês para Humanos</S.SectionTitle>
        <S.SectionText>
        Cachorrês para Humanos é um método de adestramento que ensina os cães de forma eficaz e sem sofrimento, focando na correção de comportamentos indesejados e no desenvolvimento de novas habilidades. A abordagem busca resultados duradouros e uma convivência equilibrada.
        </S.SectionText>

        {/* Links úteis */}
        <S.LinksContainer>
        
            <S.LinkButton href="/adestramento">Como funciona?</S.LinkButton>
          
        
            <S.LinkButton href="/depoimentos">Depoimentos</S.LinkButton>
          
        
            <S.LinkButton href="/contato">Contato</S.LinkButton>
          
        
            <S.LinkButton href="/emconstrucao">Dicas de Adestramento</S.LinkButton>
          
        </S.LinksContainer>
      </S.Section>
    </S.HomeContainer>
  );
};

export default Home;
