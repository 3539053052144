import styled from "styled-components";

export const TestimonialsContainer = styled.div`
  width: 80%;
  margin: 10vh auto;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
`;

export const TestimonialCard = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Quote = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  color: #555;
  margin-bottom: 10px;
`;

export const ClientName = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;
