import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import {
  ContactContainer,
  Title,
  ContactInfo,
  ContactItem,
  SocialLinks,
} from '../resources/styles/ContatoStyles';

const Contato = () => {
  return (
    <ContactContainer>
      <Title>Contato</Title>
      
      <ContactInfo>
        <ContactItem><strong>Cachorrês para Humanos</strong></ContactItem>
        <ContactItem>Adestrador: Fernando Félix</ContactItem>
        <ContactItem>
          Telefone: <a href="tel:+5541997358980">(41) 99735-8980</a>
        </ContactItem>
        <ContactItem>
          Email: <a href="mailto:cachorresparahumanos@gmail.com">cachorresparahumanos@gmail.com</a>
        </ContactItem>
        <ContactItem>CNPJ: 20.175.747/0001-98</ContactItem>
        <ContactItem>Curitiba - PR</ContactItem>
      </ContactInfo>
      
      <SocialLinks>
        <a href="https://facebook.com/cachorresparahumanos" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebookF />
        </a>
        <a href="https://www.instagram.com/cachorresparahumanos" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://www.youtube.com/cachorresparahumanos" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
          <FaYoutube />
        </a>
      </SocialLinks>
    </ContactContainer>
  );
};

export default Contato;
