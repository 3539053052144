import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.lightGray};
  padding: 100px 20px;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.6;
  color: ${props => props.theme.darkGray};
`;

export const Title = styled.h1`
  color: ${props => props.theme.darkGreen};
  font-size: 2.8rem;
  margin-bottom: 20px;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  color: ${props => props.theme.darkGreen};
  font-size: 2.2rem;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.darkGray};
  margin-bottom: 25px;
  line-height: 1.8;
`;

export const Highlight = styled.span`
  color: ${props => props.theme.neonGreen};
  font-weight: bold;
`;

export const ContactButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.neonGreen};
  color: ${props => props.theme.white};
  padding: 12px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  text-align: center;

  &:hover {
    background-color: ${props => props.theme.darkGreen};
  }
`;
