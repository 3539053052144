import React from 'react';
import { AboutContainer, Title, AboutText, HighlightLink } from '../resources/styles/SobreStyles';

const Sobre = () => {
  return (
    <AboutContainer>
      <Title>Sobre</Title>
      <AboutText>
        Bem-vindo ao <strong>Cachorrês para Humanos</strong>, um espaço dedicado ao adestramento e à educação de cães, conduzido por Fernando Félix, um adestrador apaixonado por animais. Nosso objetivo é promover uma convivência harmoniosa entre cães e seus tutores, oferecendo métodos de adestramento que são eficazes, respeitosos e baseados no amor e compreensão dos pets.
        <br /><br />
        No <strong>Cachorrês para Humanos</strong>, acreditamos que a chave para o comportamento ideal dos cães está na comunicação clara e na confiança mútua. Com isso em mente, compartilhamos dicas valiosas sobre como entender melhor o comportamento canino, ajudando os tutores a desenvolver um relacionamento equilibrado e saudável com seus cães.
        <br /><br />
        Nossas redes sociais, especialmente o perfil no Instagram <HighlightLink href="https://www.instagram.com/cachorresparahumanos" target="_blank" rel="noopener noreferrer">@cachorresparahumanos</HighlightLink>, são repletas de vídeos demonstrativos, histórias de sucesso e orientações práticas para o dia a dia. Acompanhe-nos e aprenda a transformar a rotina do seu cão com atividades, cuidados especiais e o adestramento positivo.
        <br /><br />
        Nosso compromisso é ensinar você a se comunicar melhor com seu cão, tornando a vida de ambos mais feliz e plena. 
        <br />
        Junte-se a nós nessa jornada de aprendizado e respeito pelos nossos amigos de quatro patas!
      </AboutText>
    </AboutContainer>
  );
};

export default Sobre;
