import styled from 'styled-components';

// Definindo cores baseadas na logo
const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const white = '#FFFFFF';
const gray = '#333';

// Estilos para o container principal do header
export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// Estilo para o logo à esquerda
export const Logo = styled.img`
  height: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 40px;
  }
`;

// Estilos para o container do menu de navegação
export const NavMenu = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none; // Esconder o menu quando o menu hambúrguer for exibido
  }
`;

// Estilo para os links de navegação
export const NavLink = styled.a`
  color: ${white};
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${neonGreen};
  }
`;

// Estilos para o menu hambúrguer em dispositivos móveis
export const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    background-color: ${white};
    margin: 5px 0;
    transition: 0.4s;
  }

  @media (max-width: 768px) {
    display: flex; // Exibir o menu hambúrguer em dispositivos móveis
  }
`;

// Estilos para o menu móvel (aberto)
export const MobileMenu = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${darkGreen};
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100vh;
  padding-top: 60px;
  z-index: 999;
  
  @media (min-width: 769px) {
    display: none;
  }
`;

export const MobileNavLink = styled.a`
  color: ${white};
  font-size: 1.5rem;
  padding: 15px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${neonGreen};
    color: ${darkGreen};
  }
`;
