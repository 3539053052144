import styled from 'styled-components';

// Definindo cores baseadas no design anterior
const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const white = '#FFFFFF';
const gray = '#333';

export const FooterContainer = styled.footer`
  background-color: ${gray};
  color: ${white};
  padding: 20px 0;
  text-align: center;
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterNav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FooterLink = styled.a`
  color: ${white};
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${neonGreen};
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 15px;

  a {
    color: ${white};
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: ${neonGreen};
    }
  }
`;

export const Logo = styled.img`
  height: 100px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 75px;
  }
`;

export const ContactInfo = styled.div`
  font-size: 0.9rem;
  margin-top: 10px;
  
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;
