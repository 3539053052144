import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import {
  FooterContainer,
  FooterContent,
  SocialIcons,
  ContactInfo,
  FooterSection,
  Logo
} from '../resources/styles/FooterStyles';
import logoImage from '../resources/img/logo.png';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
        <ContactInfo>
          <p>Email: cachorresparahumanos@gmail.com</p>
          <p>Telefone: (41) 99735-8980</p>
          <p>CNPJ: 20.175.747/0001-98</p>
          <p>&copy; 2024 Cachorrês Para Humanos</p>
        </ContactInfo>
        <SocialIcons>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </a>
        </SocialIcons>

        </FooterSection>
        <FooterSection>
          <Logo src={logoImage} alt="Logo" />
        </FooterSection>

        {/* Ícones das redes sociais */}


        {/* Informações de contato */}

      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
