import styled from 'styled-components';

const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const white = '#FFFFFF';

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 10vh;
  padding: 20px;
  background-color: ${darkGreen};
  color: ${white};
`;

export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  color: ${neonGreen};
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const AboutText = styled.div`
  max-width: 800px;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: justify;
  color: ${white};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const HighlightLink = styled.a`
  color: ${neonGreen};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${white};
  }
`;
