import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './resources/styles/GlobalStyles';

import Header from './components/Header'; // Seu componente de Header
import Home from './pages/Home';
import Adestramento from './pages/Adestramento';
import Blog from './pages/blog/Blog';
import Cursos from './pages/Cursos';
import Eventos from './pages/Eventos';
import Sobre from './pages/Sobre';
import Contato from './pages/Contato';
import Footer from './components/Footer';
import Auf from './pages/Auf';
import Depoimentos from './pages/Depoimentos';
import ConstructionPage from './pages/ConstructionPage';

function App() {
  return (
    <>
    <GlobalStyles/>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/adestramento" element={<Adestramento />} />
        <Route path="/blog" element={<ConstructionPage />} />
        <Route path="/cursos" element={<ConstructionPage />} />
        <Route path="/eventos" element={<ConstructionPage />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/auf" element={<Auf />} />
        <Route path="/depoimentos" element={<Depoimentos />} />
        <Route path="/emconstrucao" element={<ConstructionPage />} />
      </Routes>
      <Footer/>
    </Router>
    </> 
  );
}

export default App;
