import styled from 'styled-components';

const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const white = '#FFFFFF';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${darkGreen};
  color: ${white};
`;

export const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 30px;
  color: ${neonGreen};
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const ContactInfo = styled.div`
  text-align: center;
  font-size: 1.2rem;
  line-height: 2;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ContactItem = styled.p`
  margin: 5px 0;
  font-size: 1.3rem;

  a {
    color: ${white};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${neonGreen};
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;

  a {
    color: ${white};
    font-size: 2rem;
    transition: color 0.3s ease;

    &:hover {
      color: ${neonGreen};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;

    a {
      font-size: 1.5rem;
    }
  }
`;
