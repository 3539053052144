import styled from "styled-components";
import ConstrucaoImage from "../img/construcao.jpg"

export const ConstructionContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Banner = styled.div`
  width: 100%;
  height: 60vh;
  background-image: url(${ConstrucaoImage}); /* Insira o caminho da imagem gerada */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BannerText = styled.h1`
  color: #fff;
  font-size: 3rem;
  background-color: rgba(0, 5, 0, 0.8);
  padding: 0 25px;
  border-radius: 15px;
  margin-bottom: 20px;
`;

export const BannerSubtitle = styled.h2`
  color: #fff;
  background-color: rgba(0, 5, 0, 0.8);
  padding: 0 25px;
  border-radius: 15px;
  font-size: 1.5rem;
`;
