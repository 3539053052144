import styled from 'styled-components';
import BannerImg from '../img/bannerImg.jpg';
import { Link } from "react-router-dom";

// Definindo cores baseadas na logo
const darkGreen = '#2F4532';
const neonGreen = '#32FF6A';
const lightGray = '#E5E5E5';
const white = '#FFFFFF';

// Container principal da página Home
export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${darkGreen};
  color: ${white};

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// Estilo para o título principal
export const Title = styled.h1`
  font-size: 3rem;
  color: ${neonGreen};
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: 'Dosis', sans-serif;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Estilo para o subtítulo
export const Subtitle = styled.h2`
  font-size: 2rem;
  color: ${white};
  margin-bottom: 20px;
  font-family: 'SUSE', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// Estilo para os textos regulares
export const Text = styled.p`
  font-size: 1.25rem;
  color: ${lightGray};
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Estilo para inputs de formulários
export const Input = styled.input`
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 2px solid ${neonGreen};
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
  background-color: ${white};
  color: ${darkGreen};
  font-family: 'Arial', sans-serif;

  &:focus {
    outline: none;
    border-color: ${white};
  }

  @media (max-width: 768px) {
    padding: 8px;
    max-width: 100%;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 60vh;
  background-image: url(${BannerImg});
  background-size: cover; /* Mantém a imagem ocupando todo o espaço */
  background-position: 50% 20%; /* Centraliza a imagem */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 35vh;
`;

export const BannerText = styled.h1`
  color: white;
  padding: 0 31%;
  background-color: rgba(0,0,0, 0.3);
  font-size: 2.5rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 0 12%;
    font-size: 1.5rem;
  }
`;

export const BannerButton = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #ff9000;
  border: none;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #ff7000;
  }
`;

export const Section = styled.section`
  width: 80%;
  margin: 50px 0;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SectionText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LinkButton = styled.a`
  background-color: ${neonGreen};
  color: ${darkGreen};
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 1rem;
  &:hover {
    background-color: ${lightGray};
  }
`;

// Estilo para os botões
export const Button = styled.button`
  background-color: ${neonGreen};
  color: ${darkGreen};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Arial', sans-serif;

  &:hover {
    background-color: ${white};
    color: ${darkGreen};
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    margin: 10px;
    font-size: 1rem;
  }
`;