import React from 'react';
import { 
  Container, 
  Title, 
  SectionTitle, 
  Text, 
  Highlight, 
  ContactButton 
} from '../resources/styles/AdestramentoStyles';

const Adestramento = () => {
  return (
    <Container>
      <Title>Educação Canina Além do Básico</Title>
      <Text>
        <Highlight>Cachorrês para Humanos</Highlight> é um método que promove o aprendizado canino de forma saudável e eficaz, sem sofrimento. A abordagem trabalha problemas de comportamento e desenvolve novas habilidades, proporcionando resultados duradouros e uma rotina mais fácil e equilibrada.
      </Text>

      <SectionTitle>Funciona mesmo?</SectionTitle>
      <Text>
        Assim como nós, cachorros estão sempre aprendendo. 
        <br/> O problema? <br/>
        Muitas vezes eles aprendem errado, sem perceber que é errado. 
        <br/> A boa notícia: existem formas eficazes de ensinar qualquer cão, independente de sua idade, raça ou personalidade. 
        <br/><Highlight>Os resultados podem ser percebidos já na primeira sessão!</Highlight>
      </Text>

      <SectionTitle>Como funciona?</SectionTitle>
      <Text>
        O treinamento é feito por sessões, que podem ser presenciais ou online, com acompanhamento diário via WhatsApp. Cada sessão inclui uma avaliação comportamental completa, testes e a introdução de exercícios personalizados. Nos dias seguintes, a família aplica os exercícios e compartilha observações, permitindo ajustes mais fáceis e uma evolução mais rápida.
      </Text>

      <SectionTitle>Sessões presenciais</SectionTitle>
      <Text>
        Para quem está em <Highlight>Curitiba-PR</Highlight>, as sessões presenciais são feitas na casa da família ou em locais específicos, conforme necessário. As sessões duram cerca de uma hora e são adaptadas às necessidades individuais do cão e da família, aumentando as chances de sucesso.
      </Text>

      <SectionTitle>Sessões online</SectionTitle>
      <Text>
        Se você mora em outra cidade, as sessões por <Highlight>videochamada</Highlight> são igualmente eficazes. Cada sessão, com duração de uma hora, explica detalhadamente cada passo do treinamento, de forma simples e prática.
      </Text>

      <SectionTitle>Acompanhamento online</SectionTitle>
      <Text>
        O verdadeiro sucesso do treino depende da consistência. Após cada sessão, oferecemos um <Highlight>acompanhamento diário pelo WhatsApp</Highlight>, permitindo ajustes mais rápidos e uma análise mais profunda da evolução do comportamento, acelerando o processo de aprendizado.
      </Text>

      <SectionTitle>Planos de treinamento</SectionTitle>
      <Text>
        Para casos mais complexos ou quando uma educação completa é necessária, oferecemos <Highlight>planos de treinamento</Highlight> personalizados com múltiplas sessões e períodos de acompanhamento, adaptados às necessidades específicas de cada família.
      </Text>

      <SectionTitle>Contato</SectionTitle>
      <Text>
        Quer saber mais? <Highlight>Comece agora mesmo!</Highlight> Entre em contato pelo WhatsApp clicando no botão abaixo ou visite nossa página de contato.
      </Text>
      <ContactButton href="/contato">Fale Conosco</ContactButton>
    </Container>
  );
};

export default Adestramento;
