import { createGlobalStyle } from 'styled-components';

// Definindo cores e fontes globais
const GlobalStyles = createGlobalStyle`
  /* Reset básico para todos os navegadores */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Definindo tipografia e cores globais */
  body {
    font-family: 'Dosis', sans-serif;
    background-color: #2F4532; /* Cor escura do fundo baseada na logo */
    color: #FFFFFF; /* Texto padrão branco */
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Removendo sublinhado de todos os links */
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Definindo estilo básico para botões */
  button {
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  /* Garantindo responsividade para todos os elementos */
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  /* Estilo básico para inputs e textos */
  input, textarea {
    font-family: inherit;
    font-size: 1rem;
    padding: 10px;
    border: 2px solid #32FF6A; /* Bordas verde neon */
    border-radius: 5px;
    background-color: #FFFFFF; /* Fundo branco para inputs */
    color: #2F4532; /* Texto escuro */

    &:focus {
      outline: none;
      border-color: #FFFFFF; /* Mudança de borda ao focar */
    }
  }

  /* Estilos para headings (h1, h2, etc) */
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: #32FF6A; /* Verde neon para títulos */
  }

  /* Estilos padrão para listas */
  ul {
    list-style-type: none;
  }

  /* Definindo tamanhos padrão para headings */
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  /* Estilos para parágrafos */
  p {
    margin-bottom: 1.25rem;
    font-size: 1.1rem;
  }

   /* Estilizando a scrollbar para navegadores baseados em WebKit (Chrome, Safari) */
   ::-webkit-scrollbar {
    width: 12px;
  }

  /* Cor do trilho da scrollbar */
  ::-webkit-scrollbar-track {
    background: #333; /* Cor escura, baseado no tema da logo */
  }

  /* Cor do "polegar" da scrollbar */
  ::-webkit-scrollbar-thumb {
    background-color: #32FF6A; /* Verde neon para combinar com o design */
    border-radius: 6px;
    border: 3px solid #2F4532; /* Adiciona uma borda ao redor do "polegar" */
  }

  /* Cor do "polegar" ao passar o mouse */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #FFFFFF; /* Muda para branco ao passar o mouse */
  }

  /* Estilizando a scrollbar para Firefox */
  scrollbar-width: thin; /* Define a largura da scrollbar como "fina" */
  scrollbar-color: #32FF6A #333; /* Cor do polegar e trilho */
`;

export default GlobalStyles;
