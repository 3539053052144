import React from "react";
import * as S from "../resources/styles/DepoimentosStyles";

const Depoimentos = () => {
  return (
    <S.TestimonialsContainer>
      <S.Title>O que nossos clientes dizem</S.Title>

      <S.TestimonialCard>
        <S.Quote>"Meu cachorro agora obedece aos comandos e nossa convivência ficou muito mais tranquila. Recomendo o método Cachorrês para Humanos!"</S.Quote>
        <S.ClientName>- Ana Souza</S.ClientName>
      </S.TestimonialCard>

      <S.TestimonialCard>
        <S.Quote>"A abordagem saudável e sem sofrimento foi o que mais me atraiu, e os resultados foram incríveis!"</S.Quote>
        <S.ClientName>- João Silva</S.ClientName>
      </S.TestimonialCard>

      <S.TestimonialCard>
        <S.Quote>"Agora meu cão se comporta bem em casa e nos passeios. Ótimo trabalho!"</S.Quote>
        <S.ClientName>- Carla Mendes</S.ClientName>
      </S.TestimonialCard>
    </S.TestimonialsContainer>
  );
};

export default Depoimentos;
